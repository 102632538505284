@tailwind base;
@tailwind components;
@tailwind utilities;

a {
    @apply underline text-blue-600 hover:text-blue-800
}

.info {
    @apply text-gray-600
}

.important-information {
    @apply text-left text-lg sm:text-lg lg:text-lg text-gray-900;
    line-height: 1.5;
}

body {
    @apply bg-slate-100
}